import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import './footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p>&copy; {new Date().getFullYear()} - Todos os direitos reservados - <a href="https://github.com/Jamal-CRF" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub} /></a></p>
      </div>
    </footer>
  );
}

export default Footer;
