import React, { useState, useEffect, useRef } from 'react';
import './header.css';
import logo from '../../images/logo.png';
import { FaBars } from 'react-icons/fa';

function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }

  const closeMenu = () => {
    setShowMenu(false);
  }

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <button className="menu-icon" onClick={toggleMenu} aria-label="Toggle menu">
        <FaBars />
      </button>
      <ul className={`nav__list ${showMenu ? 'mobile' : ''}`} ref={menuRef}>
        <li className={`nav__item ${showMenu ? 'hide-on-mobile' : ''}`}>
          <a href="#home" className="nav__link" onClick={closeMenu}>
            Home
          </a>
        </li>
        <li className="nav__item">
          <a href="#procedimentos" className="nav__link" onClick={closeMenu}>
            Procedimentos
          </a>
        </li>
        <li className="nav__item">
          <a href="#atendimentos" className="nav__link" onClick={closeMenu}>
            Resultados
          </a>
        </li>
        <li className="nav__item">
          <a href="#sobre" className="nav__link" onClick={closeMenu}>
            Sobre
          </a>
        </li>
        <li className="nav__item">
          <a href="#contato" className="nav__link" onClick={closeMenu}>
            Contato
          </a>
        </li>
      </ul>
    </header>
  );
}

export default Header;
