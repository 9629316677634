import React, { useEffect, useState } from 'react';
import './home.css';
import { useSpring, animated } from 'react-spring';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import imagemBanner from '../../images/IMG_13914.png';
import imagemSobre from '../../images/IMG_13901.png';
import imagemAtendimentos from '../../images/foto_1.jpg';
import imagemAtendimentos2 from '../../images/foto_2.jpg';
import imagemAtendimentos3 from '../../images/foto_3.jpg';
import imagemAtendimentos4 from '../../images/foto_4.jpeg';
import imagemAtendimentos5 from '../../images/foto_5.jpeg';
import imagemAtendimentos6 from '../../images/foto_6.jpeg';
import imagemAtendimentos7 from '../../images/foto_7.jpeg';
import imagemAtendimentos8 from '../../images/foto_8.jpeg';
import imagemProcedimentos from '../../images/real(1).jpg';
import imagemProcedimentos2 from '../../images/real(2).jpg';
import imagemProcedimentos3 from '../../images/real(3).jpg';
import imagemProcedimentos4 from '../../images/real(4).jpg';
import imagemProcedimentos5 from '../../images/real(5).jpg';
import imagemProcedimentos6 from '../../images/real(6).jpg';
import imagemProcedimentos7 from '../../images/real(7).png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';



function Home() {

  const [isVisible, setIsVisible] = useState({
    home: false,
    procedimentos: false,
    atendimentos: false,
    sobre: false
  });

  

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  
      const homeSection = document.getElementById('home');
      const procedimentosSection = document.getElementById('procedimentos');
      const atendimentosSection = document.getElementById('atendimentos');
      const sobreSection = document.getElementById('sobre');
  
      const delay = 450; // Atraso de 1 segundo para cada seção
  
      if (homeSection && !isVisible.home && scrollPosition >= homeSection.offsetTop - window.innerHeight / 2) {
        setTimeout(() => {
          setIsVisible(prevState => ({ ...prevState, home: true }));
        }, delay);
      }
  
      if (procedimentosSection && !isVisible.procedimentos && scrollPosition >= procedimentosSection.offsetTop - window.innerHeight / 2) {
        setTimeout(() => {
          setIsVisible(prevState => ({ ...prevState, procedimentos: true }));
        }, delay - delay);
      }
  
      if (atendimentosSection && !isVisible.atendimentos && scrollPosition >= atendimentosSection.offsetTop - window.innerHeight / 2) {
        setTimeout(() => {
          setIsVisible(prevState => ({ ...prevState, atendimentos: true }));
        }, delay  - 300); // Atraso adicional de 1 segundo para a seção de atendimentos
      }
  
      if (sobreSection && !isVisible.sobre && scrollPosition >= sobreSection.offsetTop - window.innerHeight / 2) {
        setTimeout(() => {
          setIsVisible(prevState => ({ ...prevState, sobre: true }));
        }, delay  - 150); // Atraso adicional de 1,5 segundos para a seção sobre
      }
    };
  
    // Verificar o estado inicial uma vez, assim que o componente for montado
    handleScroll();
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isVisible]);

  const animationProps = useSpring({
    opacity: isVisible.sobre ? 1 : 0,
    transform: isVisible.sobre ? 'translateX(0)' : 'translateX(-100px)',
    from: { opacity: 0, transform: 'translateX(-100px)' },
    config: { duration: 1000 },
  });

  const procedimentos = [

    {
      titulo: "Rinomodelação Definitiva Estruturada",
      descricao: "Procedimento minimamente invasivo feito para corrigir imperfeições nasais, como a giba e a ponta caída, proporcionando um nariz mais harmônico e simétrico.",
      imagem: imagemProcedimentos7
    },

    {
      titulo: "Toxina botulínica",
      descricao: "Tratamento que utiliza o neuromodulador capaz de reduzir rugas e linhas de expressão, proporcionando uma aparência mais jovem e suave.",
      imagem: imagemProcedimentos
    },
    {
      titulo: "Preenchimento facial",
      descricao: "Procedimento estético no qual é utilizado preenchedor a base de ácido hialurônico de diferentes reticulações para restaurar volume facial, suavizar rugas e melhorar contornos faciais.",
      imagem: imagemProcedimentos2
    },
    {
      titulo: "Preenchimento labial",
      descricao: "Técnica que utiliza preenchedores para aumentar o volume e a definição dos lábios, proporcionando lábios mais volumosos e sensuais.",
      imagem: imagemProcedimentos3
    },
    {
      titulo: "Gerenciamento de pele",
      descricao: "Abordagem abrangente para cuidar da saúde e aparência da pele, com foco em tratamentos personalizados para hidratação, rejuvenescimento e melhoria da textura da pele.",
      imagem: imagemProcedimentos4
    },
    {
      titulo: "Bioestimuladores de colágeno",
      descricao: "Tratamento que utiliza substâncias especiais para estimular a produção de colágeno na pele, promovendo a firmeza, elasticidade e redução de rugas",
      imagem: imagemProcedimentos5
    },
    {
      titulo: "Skinboosters",
      descricao: "Procedimento no qual são injetados hidratantes profundos na pele, melhorando a hidratação, a luminosidade e a textura da pele.",
      imagem: imagemProcedimentos6
    }
    
    
  ];

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  
  return (
    <>
    <section id="home" className={isVisible.home ? 'fade-in-section is-visible' : 'fade-in-section'}>
      <div class="container">
        <div class="banner-wrapper">
          <div class="banner__container">
            <div class="banner-text">
              <h1>Referência em <br></br>Rinomodelação Estruturada Definitiva</h1>
              <p>A Dra. Kananda Galdino é especialista em realçar a harmonia facial através da Rinomodelação Estruturada Definitiva, proporcionando resultados naturais e duradouros. Com técnicas modernas e seguras, ela transforma a estética nasal, corrigindo imperfeições e valorizando a beleza única de cada paciente.</p>
              <a href="https://wa.me/5521970882424?text=Olá, vim através do seu site e quero marcar uma consulta com 5% de desconto" target="_blank" rel="noopener noreferrer" className="button-style">
                Agendar Consulta
              </a>
            </div>
            <div class="banner__image polaroid">
              <img src={imagemBanner} alt="Banner" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="procedimentos" className={isVisible.procedimentos ? 'fade-in-section is-visible' : 'fade-in-section'}>
      <h2>Procedimentos</h2>
      <div className="procedimentos__container procedimentos__grid">
        {procedimentos.map((procedimento, index) => (
          <div className="procedimento-card" key={index}>
            <div className="icon">
              <img src={procedimento.imagem} alt="Ícone do procedimento" className="procedimento-image" />
            </div>
            <h3>{procedimento.titulo}</h3>
            <p>{procedimento.descricao}</p>
          </div>
        ))}
      </div>
    </section>


    <section id="atendimentos" className={isVisible.atendimentos ? 'fade-in-section is-visible' : 'fade-in-section'}>
        <div className="carousel-container">
          <h2>Resultados</h2>
          <Slider {...carouselSettings}>
            <div>
              <img className="carousel-image" src={imagemAtendimentos4} alt="Atendimento 4" />
            </div>
            <div>
              <img className="carousel-image" src={imagemAtendimentos5} alt="Atendimento 5" />  
            </div>
            <div>
              <img className="carousel-image" src={imagemAtendimentos6} alt="Atendimento 6" />
            </div>
            <div>
              <img className="carousel-image" src={imagemAtendimentos7} alt="Atendimento 7" />
            </div>
            <div>
              <img className="carousel-image" src={imagemAtendimentos8} alt="Atendimento 8" />
            </div>
            <div>
              <img className="carousel-image" src={imagemAtendimentos} alt="Atendimento 1" />
            </div>
            <div>
              <img className="carousel-image" src={imagemAtendimentos2} alt="Atendimento 2" />
            </div>
            <div>
              <img className="carousel-image" src={imagemAtendimentos3} alt="Atendimento 3" />
            </div>
          </Slider>
        </div>
    </section>
      
    <section id="sobre" className={`fade-in-section ${isVisible.sobre ? 'is-visible' : ''}`}>
      <div className="container">
        <div className="sobre-wrapper">
          <animated.div className="sobre__image" style={animationProps}>
            <img src={imagemSobre} alt="Imagem Sobre" />
          </animated.div>
          <animated.div className="sobre-text" style={animationProps}>
              <h2>Sobre</h2>
              <p>A Dra. Kananda Galdino é uma dentista especializada em odontologia estética, com formação pela Universidade Federal do Rio de Janeiro (UFRJ). Referência na Rinomodelação Definitiva Estruturada,  um procedimento minimamente invasivo que oferece resultados impressionantes e definitivos. Com sua técnica exclusiva, a Dra. Kananda realça a simetria do nariz, corrigindo imperfeições como a giba (protuberância no dorso nasal) e definindo a ponta do nariz, proporcionando um contorno homogêneo e elegante.
              Além de sua expertise na rinomodelação, a Dra. Kananda Galdino oferece uma gama completa de procedimentos estéticos, como preenchimento facial, toxina botulínica, rejuvenescimento e prevenção do envelhecimento. Assim ela alia conhecimento técnico à sensibilidade no atendimento, garantindo que seus pacientes recebam cuidado integral, desde o planejamento até o acompanhamento pós-tratamento.
              </p>
              </animated.div>
          </div>
        </div>
    </section>

    <section id="contato">
      <h2>Contato</h2>
        <div className="contato__container">
          <div className="contato__item">
            <a href="https://wa.me/5521970882424">
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          <p>WhatsApp</p>
        </div>
        <div className="contato__item">
          <a href="tel:+5521970882424">
            <FontAwesomeIcon icon={faPhone} />
          </a>
          <p>Telefone</p>
        </div>
        <div className="contato__item">
          <a href="https://www.instagram.com/drakanandagaldino/">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <p>Instagram</p>
        </div>
        <div className="contato__item">
          <a href="https://www.facebook.com/drakanandagaldino">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <p>Facebook</p>
        </div>
        <div className="contato__item">
        <a href="https://maps.app.goo.gl/avZhrG1dWrzpjeTW9" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faMapLocationDot} />
          </a>
          <p>Barra da Tijuca - RJ</p>
        </div>
      </div>
    </section>

    </>
  );
}

export default Home;
